import React from "react";
import connection from "../scripts/connection";

import {HttpTransportType, HubConnectionBuilder} from "@microsoft/signalr";
import MessageModal from "./MessageModal";
import DateTimeSelector from "./DateTimeSelector";
import Loader from "./Loader";
import ConsultationBookingModal from "./ConsultationBookingModal";
import { decodeToken } from "react-jwt";
import TelegramBlockContent from "./TelegramBlockContent";

class TelegramRoom extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            subscribers: [],

            camShow: true,
            micEnabled: true,
        }
    }

    componentDidMount() {
        this.connectSignalR();

        // Получить данные по специалисту
    }

    componentWillUnmount() {
        this.state.hubConnection?.stop();

        this.state.session?.disconnect()
        this.OV = null;
    }

    connectChatPipeline = () => {
        const chatHubConnection = new HubConnectionBuilder().withUrl(process.env.REACT_APP_API_URL + '/chat', {
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => localStorage.getItem('token')
        }).withAutomaticReconnect().build();

        console.log("mount")

        this.setState({ chatHubConnection }, () => {
            this.state.chatHubConnection
                .start()
                .then(() => {
                    console.log('Connection started!');
                    console.log(this.props.specialistId, this.props.clientId);

                    //this.state.chatHubConnection.invoke('ReceiveChatMessages', this.props.specialistId, this.props.clientId)
                    //.catch(err => console.log(err));

                    // Receive Info about unread messages
                    this.state.chatHubConnection.invoke('ClientUnreadMessage', this.state.specialist?.id, this.getClientId())
                    .catch(err => console.log(err));
                })
                .catch(err => console.log('Error while establishing connection :(', err));


            this.state.chatHubConnection.onreconnecting(error => {

                console.error('Connection lost: ', error);
            });

           
            this.state.chatHubConnection.on('ReceiveMessage', (message) => {

                console.log('Received message');
                if (message.specialistId != this.state.specialist?.id || message.clientId != this.getClientId())
                    return;


                if (!this.state.showChat)
                    this.setState({hasUnreadmessages: true});

                // Chat Id
                // 
            });
        });

    }
    
    connectSignalR = () => {
        const hubConnection = new HubConnectionBuilder().withUrl(process.env.REACT_APP_API_URL + '/room/stream', {
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => localStorage.getItem('token')
        }).withAutomaticReconnect().build();

        this.setState({ hubConnection }, () => {
            this.state.hubConnection
                .start()
                .then(() => {
                    console.log('Connection started!');
                    this.state.hubConnection.invoke('SendAgenda');
                    this.state.hubConnection.invoke('SendActiveConsultations')
                })
                .catch(err => console.log('Error while establishing connection :(', err));


            this.state.hubConnection.onreconnecting(error => {

                console.error('Connection lost: ', error);
            })

            this.state.hubConnection.on('ReceiveToken', (token, expiresIn) => {
                console.log('Received token', token);
                this.connectVideo(token);
            })

            this.state.hubConnection.on('BookingErrorOccured', (errMessage) => {
                this.setState({errorMessage: errMessage, showError: true});
            })

            this.state.hubConnection.on('ReceiveAgenda', (agenda) => {
                console.log("received agenda", agenda)

                this.setState({timeLeft: undefined});
                

                this.setState({openRoom: true, agenda: agenda});
            });

            this.state.hubConnection.on('SessionErrorOccured', (error) => {
                console.log("Received session error:", error);
            })

            this.state.hubConnection.on('ReceiveBookingOffer', (offer) => {
                console.log("Received offer:", offer);

                this.setState({offerTime: offer.offerTime, consultationType: offer.consultationType})
            })

            this.state.hubConnection.on('BookingApproved', (offer) => {
                console.log("Booking approved:", offer);

                this.setState({message: "Консультация забронирована"})
            })

            this.state.hubConnection.on('ReceiveActiveConsultations', (cons) => {
                this.setState({consultations: cons})
            })
        });
    
        connection.AuthorizeGet('/client/specialist', 
        (success) => {
            console.log(success)
            this.setState({specialist: success.value}, () => {
                //this.connectChatPipeline();
            });

        },
        (error) => {
            console.log(error)
        },
        (fatalError) => {
            console.log(fatalError)
        });

    }

    consultationTime = (time) => {
        let dt = new Date(time);
        let day = dt.getUTCDate() < 10 ? '0' + dt.getUTCDate() : dt.getUTCDate();
        let month = (dt.getUTCMonth() + 1) < 10 ? '0' + (dt.getUTCMonth() + 1) : (dt.getUTCMonth() + 1);
        let year = dt.getUTCFullYear() % 100;

        let minutes = dt.getUTCMinutes() < 10 ? '0' + dt.getUTCMinutes() : dt.getUTCMinutes();
        let hours = dt.getUTCHours() < 10 ? '0' + dt.getUTCHours() : dt.getUTCHours();

        return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;
    }




    cancelConsultation = async () => {

        connection.AuthorizePost('/booking/cancelConsultation', {},
        (success) => {
            this.state.hubConnection.invoke('SendAgenda');
        },
        (error) => {
            console.log('Consultation cancelation error:', error);
            this.setState({message: "Не удалось отменить консультацию"});
        },
        (fatalError) => {
            console.log('Consultation cancelation error:', fatalError);
            this.setState({showError: true, errorMessage: 'Сервис временно недоступен. Повторите попытку позже.'})

        });
    }

    rescheduleConsultation = async (time, consultationType) => {


        connection.AuthorizePost('/booking/changeSpecialist', 
        { specialistId: this.state.specialist?.id, selectedTime: time , consultationType: consultationType },
        (success) => {
            this.state.hubConnection.invoke('SendAgenda');
        },
        (error) => {
            console.log('Change error occured:', error)

            this.setState({message: "Не удалось перенести консультацию"});
        },
        (fatalError) => {
            console.log('Change error occured:', fatalError)
            this.setState({showError: true, errorMessage: 'Сервис временно недоступен. Повторите попытку позже.'})
        });


        this.setState({showReschedule: false});
    }

    leaveSession = () => {
        this.state.session.disconnect();
    }

    getClientId = () => {
        let tokenData = decodeToken(localStorage.getItem('token'));

        return parseInt(tokenData.id);
    }

    bookConsultation = (time) => {
        let tokenData = decodeToken(localStorage.getItem('token'));


        connection.AuthorizePost('/booking/bookconsultation',
        {
            clientId: tokenData.id,
            specialistId: this.state.specialist.id,
            time: time,
            consultationType: this.state.consultationType
        },
        (success) => {
            this.state.hubConnection.invoke('SendAgenda');
            this.setState({showBooking: false})
        },
        (error) => {
            console.log(error)
            this.setState({message: "Не удалось забронировать консультацию"});
        },
        (fatalError) => {
            this.setState({showError: true, errorMessage: 'Сервис временно недоступен. Повторите попытку позже.'})
        })
    }

    render() {
        if (!this.state.specialist)
            return <div style={{height: '600px'}}><Loader /></div>;

        return <div className="big-card">
                    <div className="dropdown-overlay" onClick={() => this.setState({showMenu: false})} style={this.state.showMenu ? {display: 'block'} : undefined}/>




            {this.state.showBooking && this.state.specialist ?
                <ConsultationBookingModal 
                    specialistId={this.state.specialist.id}
                    //price={this.state.specialist.price}
                    specialistName={this.state.specialist.name}
                    specialistPhotoUrl={this.state.specialist.photo}
                    //onBooked={() => this.setState({payment: true})} 
                    onHide={() => this.setState({showBooking: false})}
                    onSelectedTime={(selectedTime) => this.bookConsultation(selectedTime)}
                    consultationType={this.state.consultationType}
                    consultationTypeSelector={true}
                    onConsultationTypeSelected={(consultationType) => this.setState({consultationType: consultationType})}
                />
            :    
                undefined
            }

         

            {this.state.showReschedule ?
                <DateTimeSelector show={true} specialistId={this.state.specialist?.id} onSelect={(time, consultationType) => this.rescheduleConsultation(time, consultationType)} onHide={() => this.setState({showReschedule: false})}/>
            :
                undefined
            }



            {this.state.message ?
                <MessageModal onHide={() => this.setState({message: undefined})} message={this.state.message} header={'Информация'}/>
            :
                undefined
            }

            {this.state.offerTime ?
                this.offerModal()
            :
                undefined
            }

            {
                this.state.showError ?
                <MessageModal header="Ошибка" message={this.state.errorMessage} onHide={() => this.setState({errorMessage: undefined, showError: false})}/>
                :
                undefined
            }

            <div className="video-about">
                <div className="video-master">
                    <img height={90} width={90} style={{borderRadius: '10px'}} src={this.state.specialist?.photo ? 'avatars/' + this.state.specialist?.photo : "images/avatars/selected.png"}/>
                    <div className="video-master-details">
                        <h1>Психолог:<br/> {this.state.specialist?.name} 
                        {this.state.specialist?.telegramLogin ? <a style={{verticalAlign: 'middle', margin: '0 3px'}} href={"https://t.me/" + this.state.specialist.telegramLogin}><img src="images/icons/chatDark.svg"/></a> : undefined } 
                        </h1>
                        
                    </div>
                </div>
            </div>


            <TelegramBlockContent specialistTelegram={this.state.specialist.telegramLogin} agenda={this.state?.agenda} timeLeft={this.state.timeLeft} roomOpen={() => this.setState({showOpenConsultation: true})}/>


            {this.state.agenda === null || this.state.consultationStarted ?
            <button onClick={() => this.setState({showBooking: true})} className="button-white payments-button video-button bottom-video-button">
                <img src="images/icons/blueCalendar.svg"/>
                <span>Назначить сессию</span>
            </button>
            :
            <div style={{flexFlow: 'column'}} className="bottom-video-button">
                    <button onClick={() => this.setState({showBooking: true})} className="button-white payments-button video-button bottom-video-button">Перенести сессию</button>
                    <button onClick={this.cancelConsultation} className="button-white payments-button video-button bottom-video-button">Отменить сессию</button>
            </div>
            }
        </div>;
    }
}

export default TelegramRoom;