


const Get = async function(url, success, error, requestError) {
    await SpecialRequest(process.env.REACT_APP_API_URL + url, 'GET', {}, undefined, success, error, requestError);
}


const AuthorizeGet = async function(url, success, error, requestError) {
    await SpecialRequest(process.env.REACT_APP_API_URL + url, 'GET', { 'Authorization': 'Bearer ' + localStorage.getItem('token')}, undefined, success, error, requestError);
}

const AuthorizePost = async function(url, payload, success, error, requestError) {
    
    await SpecialRequest(process.env.REACT_APP_API_URL + url, 
        'POST',
        { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        JSON.stringify(payload),
        success,
        error,
        requestError
    )
}

const Post = async function(url, payload, success, error, requestError) {
    await SpecialRequest(process.env.REACT_APP_API_URL + url, 
        'POST',
        { 'Content-Type': 'application/json'},
        JSON.stringify(payload),
        success,
        error,
        requestError
    )
}

const UploadImage = async function(url, payload, success, error, requestError) {
    await SpecialRequest(process.env.REACT_APP_API_URL + url, 
        'POST',
        {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        payload,
        success,
        error,
        requestError)
}

const SpecialRequest = async function(url, method, headers, payload, success, error, requestError) {
    try
    {
        let result = await fetch(url, {
            method: method,
            headers: headers,
            body: payload
        });

        console.log(result);

        let resultPayload = null;
        if (result.headers.get('Content-Length') != 0)
            resultPayload = await result.json();

        if (result.status === 200)
            return success(resultPayload);

        if (error)
            error(resultPayload);

    }
    catch (err)
    {
        console.log(err);
        if (requestError)
            requestError(err);
    }
}

const SendStats = async function(payload, success, error, requestError) {
    await SpecialRequest(process.env.REACT_APP_STATS_URL, 
        'POST',
        { 'Content-Type': 'application/json'},
        JSON.stringify(payload),
        success,
        error,
        requestError
    )
}

const SendActionStats = async function(payload, success, error, requestError) {
    await SpecialRequest(process.env.REACT_APP_STATS_URL + "/view?browserId=" + payload.browserId + "&actionId=" + payload.actionId, 
        'GET',
        {},
        undefined,
        success,
        error,
        requestError
    )
}

export default { AuthorizePost, Post, SpecialRequest, Get, AuthorizeGet, UploadImage, SendStats, SendActionStats };